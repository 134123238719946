import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <nav className="bg-blue-500 p-4">
      <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center sm:space-x-4 space-y-2 sm:space-y-0">
        <Link 
          to="/accueil" 
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Accueil
        </Link>
        <Link 
          to="/documents" 
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Voir ou modifier mes documents
        </Link>
        <Link to="/">
          <button className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            Se déconnecter
          </button>
        </Link>
      </div>
    </nav>
  );
}

export default Navbar;
