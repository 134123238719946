import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

function Signup() {
  const navigate = useNavigate();

  const [mairieOptions, setMairieOptions] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    mairieId: null, // Initialement null
  });
  const [errors, setErrors] = useState({}); // État pour stocker les erreurs du backend

  // Fetch des options de mairies
  const fetchMairieOptions = async () => {
    try {
      const response = await fetch("https://mymairie.fr/backoffice/apis/villes/", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      const dataArray = data.map((ville) => ({
        id: ville.mairie_id,
        value: ville.ville,
      }));
      setMairieOptions(dataArray);
    } catch (error) {
      console.error("Erreur lors de la récupération des mairies :", error);
    }
  };

  useEffect(() => {
    fetchMairieOptions();
  }, []);

  // Gérer la sélection dans le dropdown
  const handleMairieChange = (e) => {
    const selectedOption = e.target.value === "" ? null : JSON.parse(e.target.value);

    setFormData((prevData) => ({
      ...prevData,
      mairieId: selectedOption ? selectedOption.id : null, // Met à jour l'id de la mairie
      name: selectedOption ? selectedOption.value : "", // Remplit ou vide le champ name
    }));
  };

  // Gérer les changements des autres champs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Réinitialisation de la sélection mairie
  const resetMairieSelection = () => {
    setFormData((prevData) => ({
      ...prevData,
      mairieId: null, // Réinitialiser mairieId
      name: "", // Vider le champ name
    }));
  };

  // Soumission du formulaire
  const registerUser = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + "/api/register", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (data.status === "ok") {
        navigate("/");
      } else {
        // Si le backend retourne des erreurs, on les stocke dans l'état `errors`
        setErrors({ general: data.error }); // Erreur générale (non liée à un champ)
      }
    } catch (error) {
      console.error("Erreur lors de l'inscription :", error);
      setErrors({ general: "Erreur serveur. Veuillez réessayer plus tard." });
    }
  };

    // Effacer les erreurs après 5 secondes
    useEffect(() => {
      if (Object.keys(errors).length > 0) {
        const timer = setTimeout(() => {
          setErrors({});
        }, 5000); // 5000 ms = 5 secondes
  
        return () => clearTimeout(timer); // Nettoyer le timer si les erreurs changent avant la fin
      }
    }, [errors]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-blue-400">
      <div className="max-w-md w-full p-8 bg-white shadow-lg rounded-md">
        <h1 className="text-3xl text-center font-semibold mb-4">Inscription</h1>

        {/* Affichage des erreurs générales */}
        {errors.general && (
          <p className="text-red-500 text-center mb-4">{errors.general}</p>
        )}

        <form onSubmit={registerUser}>
          {/* Champ pour entrer le nom manuellement */}
          <div className="mb-4">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Mairie à renseigner manuellement"
              className={`w-full p-2 border rounded-md ${
                errors.name ? "border-red-500" : "border-gray-300"
              }`}
              disabled={formData.mairieId !== null} // Désactiver si une mairie est sélectionnée
            />
            {errors.name && (
              <p className="text-red-500 text-sm mt-1">{errors.name}</p>
            )}
          </div>

          <span className="block text-center mb-5">OU</span>

          {/* Dropdown pour sélectionner une mairie */}
          <div className="relative mb-4">
            <select
              name="mairieId"
              onChange={handleMairieChange}
              value={
                formData.mairieId
                  ? JSON.stringify({ id: formData.mairieId, value: formData.name })
                  : ""
              }
              className={`p-2 border rounded-md w-full ${
                errors.mairieId ? "border-red-500" : "border-gray-300"
              }`}
            >
              <option value="">Sélectionnez une mairie</option>
              {mairieOptions.map((mairie) => (
                <option
                  key={mairie.id}
                  value={JSON.stringify(mairie)} // Encodage de l'objet en JSON
                >
                  {mairie.value}
                </option>
              ))}
            </select>

            {/* Affichage de la croix rouge si une mairie est sélectionnée */}
            {formData.mairieId !== null && (
              <button
                type="button"
                onClick={resetMairieSelection}
                className="absolute right-[1.5rem] top-2 text-red-500 hover:text-red-700"
              >
                ❌
              </button>
            )}

            {errors.mairieId && (
              <p className="text-red-500 text-sm mt-1">{errors.mairieId}</p>
            )}
          </div>

          {/* Autres champs */}
          <div className="mb-4">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              className={`w-full p-2 border rounded-md ${
                errors.email ? "border-red-500" : "border-gray-300"
              }`}
            />
            {errors.email && (
              <p className="text-red-500 text-sm mt-1">{errors.email}</p>
            )}
          </div>

          <div className="mb-4">
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Mot de passe"
              className={`w-full p-2 border rounded-md ${
                errors.password ? "border-red-500" : "border-gray-300"
              }`}
            />
            {errors.password && (
              <p className="text-red-500 text-sm mt-1">{errors.password}</p>
            )}
          </div>

          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
          >
            M'inscrire
          </button>
        </form>

        <div className="mt-8 text-center">
          <p className="mb-2">OU</p>
          <Link to="/" className="text-blue-500 hover:underline">
            Me connecter
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Signup;
