import React from 'react';

const Footer = () => {
    return (
        <footer className="w-full bg-gray-200 py-4 text-center text-gray-600 font-medium italic">
      <div className="container mx-auto">
        By&nbsp;<a
          target="_blank"
          href="https://www.mymairie.fr/"
          className="hover:text-blue-500"
        >
          Mymairie
        </a>
      </div>
         </footer>
    );
};

export default Footer;
