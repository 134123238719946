import { useState, useEffect, useCallback, useRef } from 'react';
import axios from "axios";
import Navbar from './Navbar';
import { usePdfContext } from '../context/PdfContext';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import { jwtDecode } from 'jwt-decode';

function Home() {
    const [title, setTitle] = useState("");
    const [file, setFile] = useState("");
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [newCategory, setNewCategory] = useState('');
    const [editingCategoryId, setEditingCategoryId] = useState(null); // ID de la catégorie en cours de modification
    const [categoryNameToUpdate, setCategoryNameToUpdate] = useState(''); // Nom de la catégorie en cours de modification
    const { allPdfs, setAllPdfs } = usePdfContext();
    const [profileImage, setProfileImage] = useState("");
    const [profileImageFile, setProfileImageFile] = useState(null);
    const navigate = useNavigate();
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const token = localStorage.getItem("token");
    const [userId, setUserId] = useState(""); // Ajout de l'état pour stocker l'ID de l'utilisateur
    const [mairieId, setMairieId] = useState(""); // Id mairie
    const fullUrl = window.location.origin; // Récuperer le nom de domaine
    const [errors, setErrors] = useState({}); // stockage des erreurs pour le form pdf
    const [pdfList, setPdfList] = useState([]); // mettre à jour les pdfs dans letat
    const fileInputRef = useRef(null); // ref pour le champs file pour le reset aprés upload
    const [success, setSuccess] = useState(null); // stockage des msg success
    const [categoryDeleteErrors, setCategoryDeleteErrors] = useState({}); // error msg si on delete une catégorie qui est relié à un ou plusieurs PDF
    const logoInputRef = useRef(null); // vider le champs d'image d'upload du logo lorsque l'upload est reussi
    const [isUploading, setIsUploading] = useState(false); // etat pour l'upload du fichier logo en cours

    const authAxios = axios.create({
        baseURL: process.env.REACT_APP_API_URL+"/api",
        headers: {
            Authorization: token ? `Bearer ${token}` : null,
        },
    });

    useEffect(() => {
        if (!token) {
            alert("Vous devez être connecté pour accéder à cette page.");
            navigate('/login');
        }
    }, [token, navigate]);
  

    const getPdf = useCallback(async () => {
        if (!token) return;

        try {
            console.log("Fetching PDFs...");
            const result = await authAxios.get("/get-files");
            console.log("PDFs fetched:", result.data.data);
            setAllPdfs(result.data.data);
        } catch (error) {
            console.error("Error fetching PDF files:", error.message || error);
        }
    }, [setAllPdfs, authAxios, token]);

    const getCategories = useCallback(async () => {
        if (!token) return;

        try {
            console.log("Fetching categories...");
            const result = await authAxios.get("/get-categories");
            console.log("Categories fetched:", result.data.categories);

            // Trier les catégories par ordre alphabétique
            const sortedCategories = result.data.categories.sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
            });

            setCategories(sortedCategories);
        } catch (error) {
            console.error("Error fetching categories:", error.message || error);
        }
    }, [authAxios, token]);


    useEffect(() => {
        if (token && !isDataLoaded) {
            getCategories();
            getPdf();
            setIsDataLoaded(true); // Marquer les données comme chargées après la première exécution
        }
    }, [getCategories, getPdf, token, isDataLoaded]);

    const handleAddCategory = async () => {
        if (!newCategory.trim()) { // Vérifier si la catégorie est vide après suppression des espaces
            setErrors(prevErrors => ({ ...prevErrors, addCategoryError: "Veuillez renseigner une catégorie" }));
            setTimeout(() => {
                setErrors(prevErrors => ({ ...prevErrors, addCategoryError: null }));
            }, 5000); // Effacer le message après 5 secondes
            return;
        }
        if (!token) return;

        try {
            const result = await authAxios.post("/categories", { name: newCategory });

            // Ajout de la nouvelle catégorie et tri de la liste
            const updatedCategories = [...categories, result.data.category];
            setCategories(updatedCategories);

            setNewCategory('');

            setSuccess("Catégorie ajoutée avec succès"); // Mettre à jour l'état success
            setTimeout(() => {
                setSuccess(null);
            }, 3000);
        } catch (error) {
            console.error("Erreur lors de l'ajout de la catégorie:", error.message || error);
        }
    };

    const handleUpdateCategory = async () => {
        if (!categoryNameToUpdate || !token || !editingCategoryId) return;

        try {
            const originalCategory = categories.find(category => category._id === editingCategoryId);
            if (originalCategory.name === categoryNameToUpdate) {
                // Aucun changement, ne pas afficher le message de succès
                setEditingCategoryId(null);
                setCategoryNameToUpdate('');
                return;
            }

            const result = await authAxios.put(`/categories/${editingCategoryId}`, { name: categoryNameToUpdate });
            setCategories(categories.map(category =>
                category._id === editingCategoryId ? result.data.category : category
            ));
            setEditingCategoryId(null);
            setCategoryNameToUpdate('');
            setSuccess("Catégorie modifiée avec succès"); // Mettre à jour l'état success
            setTimeout(() => {
                setSuccess(null);
            }, 3000);
        } catch (error) {
            console.error("Error updating category:", error.message || error);
        }
    };

    const handleDeleteCategory = async (categoryId) => {
        if (!token) return;
    
        try {
            // Vérification des PDFs associés à la catégorie (vérifie bien si pdf.category est un ID ou un objet)
            const pdfsLinkedToCategory = allPdfs.filter(pdf => {
                return pdf.category && pdf.category._id === categoryId; // Assure-toi que pdf.category est un objet et comparer avec _id
            });
    
            if (pdfsLinkedToCategory.length > 0) {
                // Affichage d'un message d'erreur si des PDFs sont liés à la catégorie
                setCategoryDeleteErrors(prevErrors => ({
                    ...prevErrors,
                    [categoryId]: `Impossible de supprimer cette catégorie car elle est actuellement reliée à ${pdfsLinkedToCategory.length} PDF(s).`
                }));
    
                setTimeout(() => {
                    setCategoryDeleteErrors(prevErrors => ({ ...prevErrors, [categoryId]: null }));
                }, 5000);
    
                return;
            }
    
            // Suppression de la catégorie si aucun PDF n'est lié
            await authAxios.delete(`/categories/${categoryId}`);
            setCategories(categories.filter(category => category._id !== categoryId));
            setSuccess("Catégorie supprimée avec succès"); // Afficher le message de succès
    
            setTimeout(() => {
                setSuccess(null); // Effacer le message après un délai
            }, 3000); // 3 secondes
        } catch (error) {
            console.error("Erreur lors de la suppression de la catégorie:", error.message || error);
        }
    };
    

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    async function onSubmit(e, redirectToPdfPage = false) {
        e.preventDefault();

        const newErrors = {};

        if (!title) newErrors.title = "Le champs titre est requis.";
        if (newErrors.title) {
            setErrors(newErrors);
            return;
        }

        if (!file) {
            newErrors.file = "Le fichier PDF est requis.";
            setErrors(newErrors);
            return;
        }

        // Vérifier l'extension du fichier
        if (file && !file.name.toLowerCase().endsWith('.pdf')) {
            newErrors.file = "Le fichier doit être au format PDF.";
            setErrors(newErrors);
            return;
        }

        if (!startDate) {
            newErrors.startDate = "La date de début est requise.";
            setErrors(newErrors);
            return;
        }

        if (!endDate) {
            newErrors.endDate = "La date de fin est requise.";
            setErrors(newErrors);
            return;
        }

        if (startDate && endDate && new Date(endDate) < new Date(startDate)) {
            newErrors.endDate = "La date de fin ne peut pas être antérieure à la date de début.";
            setErrors(newErrors);
            return;
        }

        if (!selectedCategory) {
            newErrors.category = "Veuillez sélectionner une catégorie.";
            setErrors(newErrors);
            return;
        }

        setErrors({});

        if (!token) {
            alert("Vous devez être connecté pour télécharger un fichier.");
            return;
        }

        const formData = new FormData();
        formData.append("title", title);
        formData.append("file", file);
        formData.append("startDate", startDate);
        formData.append("endDate", endDate);
        formData.append("category", selectedCategory);
        formData.append("mairieId", mairieId);

        try {
            const result = await authAxios.post("/upload-files", formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });

            if (result.data.status === "ok") {
                setTitle("");
                setStartDate('');
                setEndDate('');
                setSelectedCategory('');
                if (fileInputRef.current) {
                    fileInputRef.current.value = null; // Réinitialisation du champ de fichier
                }

                // Récupérer la liste mise à jour et la mettre à jour localement
                const updatedPdfs = await getPdf(); // Assurez-vous que getPdf renvoie la liste actualisée

                // Mettre à jour la liste dans le composant ou le contexte
                setPdfList(updatedPdfs);

                if (redirectToPdfPage) {
                    navigate("/documents");
                }
                setSuccess("PDF ajouté avec succès"); // Mettre à jour l'état success
                setTimeout(() => {
                    setSuccess(null); // Effacer le message après un délai
                }, 3000); // 3 secondes

            } else {
                alert("Echec de l'upload");
            }
        } catch (error) {
            console.error("Error uploading the file:", error.message || error);
            alert("An error occurred while uploading the file.");
        }
    }

    useEffect(() => {
        getPdf().then(setPdfList); // Charger les PDF au montage
    }, []);


    useEffect(() => {
        // Récupérez l'image de profil de l'utilisateur
        const fetchUserProfileImage = async () => {
            try {
                const result = await authAxios.get("/get-user-profile"); // Assurez-vous que cette route retourne le profil utilisateur
                console.log("User profile data:", result.data); // Ajout pour vérifier la structure -> a supprimer
                setProfileImage(result.data.profileImage);
                setUserId(result.data.userId) // Stocke l'ID de l'utilisateur
                setMairieId(result.data.mairieId); // stock l'id de la mairie
            } catch (error) {
                console.error("Error fetching profile image:", error.message || error);
            }
        };

        fetchUserProfileImage();
    }, [authAxios]);

    const handleProfileImageChange = (e) => {
        if (e.target.files.length > 0) {
            setProfileImageFile(e.target.files[0]); // Stocker le fichier dans l'état
        }
    };

    const handleProfileImageUpload = async () => {
        if (isUploading || !profileImageFile) return; // Empêcher le double upload ou l'absence de fichier

        setIsUploading(true);

        try {
            // Étape 1 : Supprimer l'ancien logo s'il existe
            if (profileImage) {
                await authAxios.delete("/delete-profile-image");
                console.log("Ancien logo supprimé avec succès.");
            }

            // Étape 2 : Télécharger le nouveau logo
            const formData = new FormData();
            formData.append("profileImage", profileImageFile);

            const result = await authAxios.post("/upload-profile-image", formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });

            // Mise à jour du nouvel état
            setProfileImage(result.data.profileImage);
            setSuccess("Logo ajouté avec succès");
            setTimeout(() =>
            {
                setSuccess(null); // Effacer le message après un délai
            }, 3000); // 3 secondes

            if (logoInputRef.current) {
                logoInputRef.current.value = '';
            }
            setProfileImageFile(null);
        } catch (error) {
            console.error("Erreur lors du téléchargement du logo :", error.message || error);
        } finally {
            setIsUploading(false);
        }
    };

    const handleProfileImageDelete = async () => {
        try {
            await authAxios.delete("/delete-profile-image");
            setProfileImage(""); // Supprime l'image de l'état local
            setSuccess("Logo supprimé avec succès"); // Afficher le message de succès
            setTimeout(() =>
            {
                setSuccess(null); // Effacer le message après un délai
            }, 3000); // 3 secondes
        } catch (error) {
            console.error("Error deleting profile image:", error.message || error);
        }
    };



    return (
        <>
            <Navbar />
            <div className="fixed top-0 left-0 w-full z-50">
                {success && ( // Afficher le bandeau si success est vrai
                    <div className="bg-green-300 text-green-800 p-2 text-center font-bold">
                        {success}
                    </div>
                )}
            </div>
            <div>
                <div className="mt-5 flex justify-center space-x-8 items-start flex-wrap">
                    {/* Formulaire d'ajout de PDF à gauche */}
                    <div className="p-8 bg-white border shadow-md rounded-md w-full md:w-1/2 max-w-2xl">
                        <h1 className="text-3xl font-bold text-blue-500 mb-4 border-b-2 border-blue-300 pb-2">Ajouter un affichage légal</h1>
                        <form onSubmit={(e) => onSubmit(e, false)} className="mt-6">
        <textarea
            type="text"
            placeholder="Titre"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className="w-full border border-gray-300 rounded-md p-2 mb-4"
        />
                            {errors.title && (
                                <div className="mt-2 text-sm text-red-600 flex items-center space-x-1 font-bold">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-10a1 1 0 10-2 0v4a1 1 0 002 0V8zm0 6a1 1 0 10-2 0v2a1 1 0 002 0v-2z" clipRule="evenodd" />
                                    </svg>
                                    <span>{errors.title}</span>
                                </div>
                            )}
                            <input
                                type="file"
                                accept="application/pdf"
                                onChange={(e) => setFile(e.target.files[0])}
                                required
                                className="w-full border border-gray-300 rounded-md p-2 mb-4"
                                ref={fileInputRef}
                            />
                            {errors.file && (
                                <div className="mt-2 text-sm text-red-600 flex items-center space-x-1 font-bold">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-10a1 1 0 10-2 0v4a1 1 0 002 0V8zm0 6a1 1 0 10-2 0v2a1 1 0 002 0v-2z" clipRule="evenodd" />
                                    </svg>
                                    <span>{errors.file}</span>
                                </div>
                            )}
                            <div className="mb-4">
                                <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">Date de début</label>
                                <input
                                    id="startDate"
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    required
                                    className="w-full border border-gray-300 rounded-md p-2 mb-4"
                                />
                                {errors.startDate && (
                                    <div className="mt-2 text-sm text-red-600 flex items-center space-x-1 font-bold">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-10a1 1 0 10-2 0v4a1 1 0 002 0V8zm0 6a1 1 0 10-2 0v2a1 1 0 002 0v-2z" clipRule="evenodd" />
                                        </svg>
                                        <span>{errors.startDate}</span>
                                    </div>
                                )}
                            </div>
                            <div className="mb-4">
                                <label htmlFor="endDate" className="block text-sm font-medium text-gray-700">Date de fin</label>
                                <input
                                    id="endDate"
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    required
                                    className="w-full border border-gray-300 rounded-md p-2 mb-4"
                                />
                                {errors.endDate && (
                                    <div className="mt-2 text-sm text-red-600 flex items-center space-x-1 font-bold">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-10a1 1 0 10-2 0v4a1 1 0 002 0V8zm0 6a1 1 0 10-2 0v2a1 1 0 002 0v-2z" clipRule="evenodd" />
                                        </svg>
                                        <span>{errors.endDate}</span>
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <select
                                    value={selectedCategory}
                                    onChange={handleCategoryChange}
                                    className="w-full border border-gray-300 rounded-md p-2 mb-4"
                                >
                                    <option value="">Selectionner une catégorie</option>
                                    {categories.map((category) => (
                                        <option key={category._id} value={category._id}>{category.name}</option>
                                    ))}
                                </select>
                                {errors.category && (
                                    <div className="mt-2 text-sm text-red-600 flex items-center space-x-1 font-bold">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-10a1 1 0 10-2 0v4a1 1 0 002 0V8zm0 6a1 1 0 10-2 0v2a1 1 0 002 0v-2z" clipRule="evenodd" />
                                        </svg>
                                        <span>{errors.category}</span>
                                    </div>
                                )}
                            </div>

                            <button type="submit" className="w-full transition-colors hover:bg-blue-700 bg-blue-600 text-white p-2 rounded-md mb-4 font-bold">
                                Enregistrer et ajouter un nouveau document
                            </button>
                            <button
                                type="button"
                                className="w-full bg-green-600 text-white p-2 rounded-md mb-4 transition-colors hover:bg-green-700 font-bold"
                                onClick={(e) => onSubmit(e, true)}
                            >
                                Enregistrer et voir mes documents
                            </button>
                        </form>
                    </div>

                    {/* Bloc de gestion du logo à droite */}
                    <div className="p-8 bg-white border shadow-md rounded-md w-full md:w-1/2 max-w-xs flex flex-col justify-between" style={{ minHeight: '634.5px' }}>
                        <h2 className="text-3xl font-bold text-blue-500 mb-4 border-b-2 border-blue-300 pb-2">Mon Logo</h2>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleProfileImageChange}
                            className="w-full border border-gray-300 rounded-md p-2 mb-4"
                            ref={logoInputRef}
                        />
                        <button
                            onClick={handleProfileImageUpload}
                            className="w-full bg-blue-600 text-white p-2 rounded-md mb-4 transition-colors hover:bg-blue-700 font-bold"
                            disabled={!profileImageFile}
                        >
                            Enregistrer mon logo
                        </button>

                        {profileImage && (
                            <div className="text-center">
                                <img
                                    src={process.env.REACT_APP_API_URL+`${profileImage}`}
                                    alt="Profil"
                                    className="w-[100px] h-auto object-cover mx-auto mb-4"
                                />
                                <button
                                    onClick={handleProfileImageDelete}
                                    className="text-white bg-red-600 hover:bg-red-700 px-4 py-2 rounded-md transition-colors mt-2 font-bold"
                                >
                                    Supprimer mon logo
                                </button>
                            </div>
                        )}

                        {(userId || mairieId) && (
                            <a
                                target="_blank"
                                href={`${fullUrl}${mairieId ? `/mairie/${mairieId}` : `/id/${userId}`}`}
                                className="inline-block bg-blue-600 text-white text-md font-semibold py-2 px-4 rounded-md shadow-md hover:bg-blue-700 transition-colors mt-auto mb-4"
                                style={{ marginBottom: '15px' }}
                            >
                                Ce qui est visible par le citoyen
                            </a>
                        )}
                    </div>
                </div>

                {/* Bloc de gestion des catégories */}
                <div className="flex justify-center mt-5">
                    <div className="bg-white border shadow-md rounded-md w-full md:w-1/2">
                        <div className="p-8">
                            <h2 className="text-3xl font-bold text-blue-500 mb-4 border-b-2 border-blue-300 pb-2">Gestion des catégories</h2>

                            <div className="mb-4">
        <textarea
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            placeholder="Nouvelle catégorie"
            className="w-full border border-gray-300 rounded-md p-2 w-9/10 md:w-3/4"
        />
                                {errors.addCategoryError && ( // Afficher l'erreur si elle existe
                                    <div className="text-red-500 text-sm mt-1 font-bold">{errors.addCategoryError}</div>
                                )}
                            </div>

                            <button
                                onClick={handleAddCategory}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            >
                                Ajouter une catégorie
                            </button>
                        </div>

                        <div className="bg-white">
                            <ul className="">
                                {categories.map((category, index) => (
                                    <li
                                        key={category._id}
                                        className={`border-b border-gray-200 py-8 ${index % 2 === 0 ? 'bg-gray-100' : ''}`}
                                    >
                                        {/* Nom de la catégorie */}
                                        {editingCategoryId === category._id ? (
                                            <textarea
                                                type="text"
                                                value={categoryNameToUpdate}
                                                onChange={(e) => setCategoryNameToUpdate(e.target.value)}
                                                className="w-9/10 md:w-3/4 border border-gray-300 rounded-md p-2"
                                            />
                                        ) : (
                                            <span className="text-xl font-medium">{category.name}</span>
                                        )}

                                        {/* Boutons d'action */}
                                        <div className="mt-2 flex justify-center space-x-2">
                                            {editingCategoryId === category._id ? (
                                                <button
                                                    onClick={handleUpdateCategory}
                                                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                                >
                                                    Enregistrer
                                                </button>
                                            ) : (
                                                <button
                                                    onClick={() => {
                                                        setEditingCategoryId(category._id);
                                                        setCategoryNameToUpdate(category.name);
                                                    }}
                                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                                >
                                                    Modifier
                                                </button>
                                            )}

                                            <button
                                                onClick={() => handleDeleteCategory(category._id)}
                                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                            >
                                                Supprimer
                                            </button>
                                        </div>
                                        {categoryDeleteErrors[category._id] && (
                                            <div className="text-red-500 text-sm mt-1 font-bold">{categoryDeleteErrors[category._id]}</div>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

            </div>

            <Footer />               
        </>
    );
}

export default Home;
