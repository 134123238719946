import { useState, useEffect } from "react";
import axios from "axios";

function Account() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    // Charger les informations utilisateur au chargement du composant
    useEffect(() => {
        const fetchAccountDetails = async () => {
            try {
                const token = localStorage.getItem("token"); // Récupération du token
                const authAxios = axios.create({
                    baseURL: process.env.REACT_APP_API_URL + "/api", // Ajout de /api
                    headers: {
                        Authorization: token ? `Bearer ${token}` : null,
                    },
                });

                const { data } = await authAxios.get("/myprofile"); // Utilisation d'authAxios

                setName(data.name || "");
                setEmail(data.email || "");
            } catch (error) {
                console.error("Erreur lors du chargement des informations :", error);
                setErrorMessage("Impossible de charger vos informations.");
            }
        };

        fetchAccountDetails();
    }, []);

    // Mettre à jour les informations utilisateur
    const handleUpdateAccount = async (e) => {
        e.preventDefault();
        setSuccessMessage(null);
        setErrorMessage(null);

        try {
            const token = localStorage.getItem("token"); // Récupération du token
            const authAxios = axios.create({
                baseURL: process.env.REACT_APP_API_URL + "/api", // Assure que le baseURL est bien défini
                headers: {
                    Authorization: token ? `Bearer ${token}` : null,
                },
            });

            // Utilisation d'authAxios pour envoyer la requête PUT
            await authAxios.put(
                "/update-profile", // L'URL relative, sans répéter /api
                { name, email, password } // Les données à envoyer
            );

            setSuccessMessage("Vos informations ont été mises à jour avec succès !");
        } catch (error) {
            console.error("Erreur lors de la mise à jour :", error);
            setErrorMessage("Une erreur est survenue lors de la mise à jour de vos informations.");
        }
    };

    return (
        <div className="container mx-auto px-4 py-8">
            {/* Titre principal */}
            <h1 className="text-blue-500 text-4xl font-extrabold mb-8 text-center text-gray-800 tracking-wide">
                Gestion du Compte
            </h1>

            {/* Conteneur principal */}
            <div className="bg-white shadow-lg rounded-lg p-8 max-w-3xl mx-auto">

                {/* Messages de succès ou d'erreur */}
                {successMessage && (
                    <p className="text-sm text-green-700 bg-green-100 border border-green-300 p-4 rounded-md mb-6">
                        {successMessage}
                    </p>
                )}
                {errorMessage && (
                    <p className="text-sm text-red-700 bg-red-100 border border-red-300 p-4 rounded-md mb-6">
                        {errorMessage}
                    </p>
                )}

                {/* Formulaire */}
                <form onSubmit={handleUpdateAccount} className="space-y-6">
                    {/* Champ pour le nom */}
                    <div>
                        <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700 mb-1"
                        >
                            Nom
                        </label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-blue-400 focus:border-blue-400 placeholder-gray-400"
                            placeholder="Votre nom"
                        />
                    </div>

                    {/* Champ pour l'email */}
                    <div>
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700 mb-1"
                        >
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-blue-400 focus:border-blue-400 placeholder-gray-400"
                            placeholder="Votre email"
                        />
                    </div>

                    {/* Champ pour le mot de passe */}
                    <div>
                        <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700 mb-1"
                        >
                            Nouveau mot de passe
                        </label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-blue-400 focus:border-blue-400 placeholder-gray-400"
                            placeholder="Votre nouveau mot de passe"
                        />
                    </div>

                    {/* Bouton de soumission */}
                    <div className="text-center">
                        <button
                            type="submit"
                            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 rounded-lg shadow-md focus:outline-none focus:ring focus:ring-blue-300"
                        >
                            Mettre à jour
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Account;