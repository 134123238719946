import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "./Navbar";
import Footer from "./Footer";

function SuperAdminValneo() {
    const [users, setUsers] = useState([]);
    const [success, setSuccess] = useState(null);
    const [errors, setErrors] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false); // Contrôle de la popup
    const [userToDelete, setUserToDelete] = useState(null); // Utilisateur à supprimer
    const navigate = useNavigate();
    const token = localStorage.getItem("token");

    const authAxios = axios.create({
        baseURL: process.env.REACT_APP_API_URL + "/api",
        headers: {
            Authorization: token ? `Bearer ${token}` : null,
        },
    });

    // Récupération des utilisateurs
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                console.log("Tentative de récupération des utilisateurs.");
                const result = await authAxios.get("/superadmin/users");
                setUsers(result.data.users || []);
                console.log("Utilisateurs récupérés:", result.data.users);
            } catch (error) {
                console.error("Erreur lors de la récupération des utilisateurs :", error.message || error);
                setErrors("Erreur lors de la récupération des utilisateurs.");
            }
        };

        fetchUsers();
    }, [authAxios]);


    // Supprimer un utilisateur
    const handleDeleteUser = async () => {
        try {
            await authAxios.delete(`/superadmin/users/${userToDelete}`);
            setUsers(users.filter((user) => user._id !== userToDelete));
            setSuccess("Utilisateur supprimé avec succès.");
        } catch (error) {
            console.error("Erreur lors de la suppression de l'utilisateur :", error.message || error);
            setErrors("Erreur lors de la suppression de l'utilisateur.");
        } finally {
            setShowConfirmation(false); // Fermer la popup après la suppression
            setUserToDelete(null); // Réinitialiser
        }
    };

    // Afficher la popup de confirmation
    const handleDeleteConfirmation = (userId) => {
        setUserToDelete(userId); // Définir l'utilisateur à supprimer
        setShowConfirmation(true); // Afficher la popup
    };

    return (
        <>
            <Navbar />
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold text-blue-500 border-b-2 border-blue-300 pb-2">Gestion des Mairies</h1>

                {/* Message de succès ou erreur */}
                {success && (
                    <p className="text-green-600 bg-green-100 p-4 rounded-md mb-4">{success}</p>
                )}
                {errors && (
                    <p className="text-red-600 bg-red-100 p-4 rounded-md mb-4">{errors}</p>
                )}

                {/* Popup de confirmation */}
                {showConfirmation && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white rounded-lg shadow-lg p-6 w-96">
                            <h2 className="text-lg font-semibold mb-4">Confirmation</h2>
                            <p className="mb-4">Êtes-vous sûr de vouloir supprimer cet utilisateur ou toutes les données associées ? Cette action est irréversible!</p>
                            <div className="flex justify-center space-x-4">
                                <button
                                    onClick={() => setShowConfirmation(false)} // Annuler
                                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded"
                                >
                                    Annuler
                                </button>
                                <button
                                    onClick={handleDeleteUser} // Confirmer
                                    className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded"
                                >
                                    Confirmer
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Table des utilisateurs */}
                <div className="overflow-x-auto bg-white shadow-md rounded-lg">
                    <table className="min-w-full table-auto">
                        <thead className="bg-gray-100 text-gray-700">
                            <tr>
                                <th className="py-3 px-4 text-center">Mairie</th>
                                <th className="py-3 px-4 text-center">Email</th>
                                <th className="py-3 px-4 text-center">MairieID</th>
                                <th className="py-3 px-4 text-center">UserID</th>
                                <th className="py-3 px-4 text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-700">
                            {users && users.length > 0 ? (
                                users.map((user) => (
                                    <tr
                                        key={user._id}
                                        className="border-b hover:bg-gray-50"
                                    >
                                        <td className="py-3 px-4">{user.name || "Mairie non défini"}</td>
                                        <td className="py-3 px-4">{user.email || "Email non défini"}</td>
                                        <td className="py-3 px-4 text-center">
                                            {user.mairieId || "Mairie ID non défini"}
                                        </td>
                                        <td className="py-3 px-4 text-center">
                                            {user._id || "ID non défini"}
                                        </td>
                                        <td className="py-3 px-4">
                                            <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-2 sm:space-y-0 justify-center">
                                                <button
                                                    className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-4 font-bold rounded focus:outline-none focus:shadow-outline mr-4"
                                                >
                                                    Accéder
                                                </button>
                                                <button
                                                    onClick={() =>
                                                        handleDeleteConfirmation(user._id)
                                                    }
                                                    className="bg-red-600 hover:bg-red-700 text-white font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline"
                                                >
                                                    Supprimer
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td
                                        colSpan="5"
                                        className="py-4 text-center text-gray-500"
                                    >
                                        Aucun utilisateur trouvé.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default SuperAdminValneo;
