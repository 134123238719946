import React from 'react';

const Pagination = ({ currentPage, itemsPerPage, totalItems, paginate, setItemsPerPage }) => {
  // Calculer le nombre total de pages
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const pageNumbers = [];
  
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  // Fonction pour changer le nombre d'éléments par page
  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = Number(e.target.value);

    // 1. Calculer l'index de l'élément en haut de la page actuelle
    const currentItemIndex = (currentPage - 1) * itemsPerPage;

    // 2. Calculer la nouvelle page en fonction de l'index et du nouveau nombre d'éléments par page
    const newPage = Math.floor(currentItemIndex / newItemsPerPage) + 1;

    // 3. Mettre à jour la page actuelle avec la nouvelle page calculée
    paginate(newPage); 

    // 4. Mettre à jour le nombre d'éléments par page
    setItemsPerPage(newItemsPerPage); 
  };

  // Vérifier si aucun PDF n'est trouvé
  const noItemsFound = totalItems === 0;

  return (
    <div className="pagination-container flex justify-between items-center mt-6 mb-6">
      {/* Sélecteur de nombre d'éléments par page */}
      <div class="pagination-elements">
        <label htmlFor="itemsPerPage" className="mr-2">Affichage par page :</label>
        <select
          id="itemsPerPage"
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          className="p-2 border border-gray-300 rounded-md"
        >
          <option value={30}>30</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>

      {/* Pagination */}
      <div class="pagination-buttons">
        {/* Bouton "Précédent" */}
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1 || noItemsFound}  // Désactive si on est à la première page ou s'il n'y a pas de fichiers
          className={`px-4 py-2 mx-1 bg-gray-200 rounded-md ${noItemsFound ? 'disabled:opacity-50' : ''}`}
        >
          Précédent
        </button>
        
        {/* Affichage des boutons de page */}
        {pageNumbers.map(number => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={`px-4 py-2 mx-1 rounded-md ${currentPage === number ? 'bg-teal-400 text-white' : 'bg-gray-200'}`}
          >
            {number}
          </button>
        ))}

        {/* Bouton "Suivant" */}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === totalPages || noItemsFound}  // Désactive si on est à la dernière page ou s'il n'y a pas de fichiers
          className={`px-4 py-2 mx-1 bg-gray-200 rounded-md ${noItemsFound ? 'disabled:opacity-50' : ''}`}
        >
          Suivant
        </button>
      </div>
    </div>
  );
};

export default Pagination;
