import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home';
import Signup from './components/authentication/Signup';
import Login from './components/authentication/Login';
import UploadedFiles from './components/UploadedFiles';
import { PdfContextProvider } from './context/PdfContext';
import './App.css';
import FrontPdf from './components/FrontPdf';
import SuperAdminValneo from './components/SuperAdminValneo';
import Account from './components/Account';

// Composant ProtectedRoute pour rediriger vers la page de login si l'utilisateur n'est pas authentifié
const ProtectedRoute = ({ element, ...rest }) => {
  const token = localStorage.getItem('token');
  return token ? element : <Navigate to="/" />;
};

function App() {
  return (
    <PdfContextProvider>
      <Router>
        <div className="App">
        <Routes>
  {/* Routes publiques */}
  <Route path="/" element={<Login />} />
  <Route path="/inscription" element={<Signup />} />

  {/* Routes dynamiques */}
  <Route path="/mairie/:mairieId" element={<FrontPdf />} /> {/* Route dynamique pour mairieId */}
  <Route path="/id/:userId" element={<FrontPdf />} /> {/* Route dynamique pour userId */}

  {/* Routes protégées */}
  <Route path="/accueil" element={<ProtectedRoute element={<Home />} />} />
  <Route path="/documents" element={<ProtectedRoute element={<UploadedFiles />} />} />
  <Route path="/compte" element={<ProtectedRoute element={<Account />} />} />

  {/* Route pour le SuperAdmin */}
  <Route path="/superadminvalneo" element={<ProtectedRoute element={<SuperAdminValneo />} />} />
</Routes>

        </div>
      </Router>
    </PdfContextProvider>
  );
}

export default App;